function formElementGenerator() {
    var inputTypes = ["button", "checkbox", "color", "date", "datetime-local", "email", "file", "hidden", "image", "month", "number", "password", "radio", "range", "reset", "search", "submit", "tel", "text", "time", "url", "week", "birthdate", "expirydate", "mobile", "email", 'phone'];
    this.make = function (type, configs) {
        if (inputTypes.indexOf(type.toLowerCase()) > -1) {
            return this.input(configs);
        }
        if (this[type] == undefined) {
            throw new Error('Undefined Type: ' + type);
        }
        return this[type](configs);
    }
    this.input = function (configs) {
        return '<input ' + parseAttributes(configs) + '/>';
    }

    var parseAttributes = function (attributes) {
        var parsedConfigs = [];
        for (var index in attributes) {
            parsedConfigs.push(index + '="' + attributes[index] + '"');
        }
        return parsedConfigs.join(' ');
    }

    this.select = function (configs) {
        var options = '';
        for (var selectIndex in configs.items) {
            options += '<option ' + parseAttributes({ value: selectIndex }) + '>' + trans(configs.items[selectIndex]) + '</option>';
        }
        delete configs.items;
        return '<select ' + parseAttributes(configs) + '>' + options + '</select>';
    }

    this.CountryCode = function (configs) {
        var option = '';
        if (!$.isEmptyObject(configs.option)) {
            option += '<option value=' + configs.option.id + ' title=' + configs.option.title + '-' + configs.option.abb + '>' + configs.option.title + '-' + configs.option.abb + '</option>'
        }
        delete configs.type;
        return '<select ' + parseAttributes(configs) + '>' + option + '</select>';
    }

    this.cityCode = function (configs) {
        var option = '';
        if (!$.isEmptyObject(configs.option)) {
            option += '<option value=' + configs.option.id + ' title=' + configs.option.title + '>' + configs.option.title + '</option>'
        }
        delete configs.type;
        return '<select ' + parseAttributes(configs) + '>' + option + '</select>';
    }

    this.countryId = function (configs) {
        delete configs.type;
        return '<select ' + parseAttributes(configs) + '></select>';
    }

    this.cityId = function (configs) {
        delete configs.type;
        return '<select ' + parseAttributes(configs) + '></select>';
    }

    this.textArea = function (configs) {
        delete configs.type;
        return '<textarea ' + parseAttributes(configs) + '/></textarea>';
    }

    this.textarea = function (configs) {
        delete configs.type;
        return '<textarea ' + parseAttributes(configs) + '/></textarea>';
    }
}